import React from "react";
import { Container } from "react-bootstrap";
import SeasonGuessesComponent from "../components/SeasonGuessesComponent";
import { useQuery } from "@tanstack/react-query";
import { fetchUserLeaguesWithNames } from "../services/supabaseService";

const SeasonGuesses = () => {
  // Fetch the leagues the user is in
  const {
    data: leaguesDataFetching,
    isLoading: leaguesLoading,
    error: leaguesError,
  } = useQuery({
    queryKey: ["leagues"],
    queryFn: fetchUserLeaguesWithNames,
    staleTime: 1000 * 60 * 5,
  });

  if (leaguesLoading) {
    return <p>Loading...</p>;
  }

  if (leaguesError) {
    return <p>Error fetching leagues</p>;
  }

  if (!leaguesDataFetching || leaguesDataFetching.length === 0) {
    return <p>No leagues found</p>;
  }
  console.log("leaguesDataFetching", leaguesDataFetching);
  return (
    <Container className="mt-4">
      <SeasonGuessesComponent leaguesData={leaguesDataFetching} />
    </Container>
  );
};

export default SeasonGuesses;
