import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { supabase } from "../supabaseClient"; // Import Supabase client
import { updatePlayerName } from "../services/supabaseService"; // Import updatePlayerName function

const UserManagement = () => {
  const [playerName, setPlayerName] = useState("");
  const [newName, setNewName] = useState("");
  const [playerId, setPlayerId] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Fetch the current player's information
  useEffect(() => {
    const fetchPlayerInfo = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        setPlayerId(user.id);

        // Fetch player name from the players table
        const { data, error } = await supabase
          .from("players")
          .select("name")
          .eq("id", user.id)
          .single();

        if (data) {
          setPlayerName(data.name);
          setNewName(data.name); // Initialize the new name with the current name
        } else if (error) {
          console.error("Error fetching player name:", error);
        }
      }
    };

    fetchPlayerInfo();
  }, []);

  // Handle the form submission to change the player's name
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!newName.trim()) {
      setError("Name cannot be empty");
      return;
    }

    const { success, error } = await updatePlayerName(playerId, newName);

    if (success) {
      setMessage("Name updated successfully!");
      setPlayerName(newName);
      setError("");
    } else {
      setError("Error updating name");
    }
  };

  return (
    <Container className="mt-4">
      <h2>User Management</h2>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Current Name</Form.Label>
          <Form.Control type="text" value={playerName} disabled />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>New Name</Form.Label>
          <Form.Control
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Update Name
        </Button>
      </Form>
    </Container>
  );
};

export default UserManagement;
