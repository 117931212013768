// src/components/Register.js

import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Alert } from "react-bootstrap";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState("success");
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Validate password and confirm password match
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      setMessageVariant("danger");
      return;
    }

    // Sign up the user
    const { error } = await supabase.auth.signUp({
      email,
      password,
    });

    if (error) {
      setMessage(`Error signing up: ${error.message}`);
      setMessageVariant("danger");
    } else {
      setMessage("Registration successful!");
      setMessageVariant("success");
      // Optionally, redirect the user to login page after a delay
      setTimeout(() => {
        navigate("/auth");
      }, 3000);
    }
  };

  return (
    <Container style={{ maxWidth: "500px", marginTop: "50px" }}>
      <h2>Register</h2>
      {message && <Alert variant={messageVariant}>{message}</Alert>}
      <Form onSubmit={handleSignUp}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPassword" style={{ marginTop: "20px" }}>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter a password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group
          controlId="formConfirmPassword"
          style={{ marginTop: "20px" }}
        >
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" style={{ marginTop: "20px" }}>
          Register
        </Button>
      </Form>
      <p style={{ marginTop: "20px" }}>
        Already have an account?{" "}
        <Button variant="link" onClick={() => navigate("/auth")}>
          Sign In
        </Button>
      </p>
    </Container>
  );
};

export default Register;
