import React, { useState } from "react";
import { Form, Button, Alert, Container } from "react-bootstrap";
import { createLeague } from "../services/supabaseService"; // Import service function

const CreateLeague = () => {
  const [leagueName, setLeagueName] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleCreateLeague = async (e) => {
    e.preventDefault();
    try {
      const data = await createLeague(leagueName); // Call createLeague without inviteCode
      console.log(data);

      setInviteCode(data.inviteCode); // Set the generated invite code
      setSuccess(
        "League created successfully! Your invite code is: " + data.invite_code
      );
      setError(null);
    } catch (err) {
      setError("Failed to create league: " + err.message);
      setSuccess(null);
    }
  };

  return (
    <Container>
      <h3>Create a New League</h3>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleCreateLeague}>
        <Form.Group controlId="leagueName">
          <Form.Label>League Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter league name"
            value={leagueName}
            onChange={(e) => setLeagueName(e.target.value)}
            required
          />
        </Form.Group>

        <Button className="mt-3" variant="primary" type="submit">
          Create League
        </Button>
      </Form>

      {inviteCode && (
        <div className="mt-3">
          <Alert variant="info">
            Your league invite code: <strong>{inviteCode}</strong>
          </Alert>
        </div>
      )}
    </Container>
  );
};

export default CreateLeague;
