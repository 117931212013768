// src/components/AuthPage.js

import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Alert } from "react-bootstrap";

const AuthPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState("success");
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setMessage(error.message);
      setMessageVariant("danger");
    } else {
      setMessage("Sign-in successful!");
      setMessageVariant("success");
      navigate("/");
    }
  };

  return (
    <Container style={{ maxWidth: "500px", marginTop: "50px" }}>
      <h2>Sign In</h2>
      {message && <Alert variant={messageVariant}>{message}</Alert>}
      <Form onSubmit={handleSignIn}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPassword" style={{ marginTop: "20px" }}>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" style={{ marginTop: "20px" }}>
          Sign In
        </Button>
      </Form>
      <p style={{ marginTop: "20px" }}>
        Don't have an account?{" "}
        <Button variant="link" onClick={() => navigate("/register")}>
          Register
        </Button>
      </p>
      <p>
        Forgot your password?{" "}
        <Button variant="link" onClick={() => navigate("/forgot-password")}>
          Reset Password
        </Button>
      </p>
    </Container>
  );
};

export default AuthPage;
