import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Table,
} from "react-bootstrap";
import {
  handleSeasonGuesses,
  fetchCurrentGuesses,
  fetchSeasonGuessesForLeague,
} from "../services/supabaseService";
import { useQuery } from "@tanstack/react-query";

// Separate lists for each division
const afcNorthTeams = [
  "Baltimore Ravens",
  "Cincinnati Bengals",
  "Cleveland Browns",
  "Pittsburgh Steelers",
];
const afcEastTeams = [
  "Buffalo Bills",
  "Miami Dolphins",
  "New England Patriots",
  "New York Jets",
];
const afcWestTeams = [
  "Denver Broncos",
  "Kansas City Chiefs",
  "Las Vegas Raiders",
  "Los Angeles Chargers",
];
const afcSouthTeams = [
  "Houston Texans",
  "Indianapolis Colts",
  "Jacksonville Jaguars",
  "Tennessee Titans",
];

const nfcNorthTeams = [
  "Chicago Bears",
  "Detroit Lions",
  "Green Bay Packers",
  "Minnesota Vikings",
];
const nfcEastTeams = [
  "Dallas Cowboys",
  "New York Giants",
  "Philadelphia Eagles",
  "Washington Commanders",
];
const nfcWestTeams = [
  "Arizona Cardinals",
  "Los Angeles Rams",
  "San Francisco 49ers",
  "Seattle Seahawks",
];
const nfcSouthTeams = [
  "Atlanta Falcons",
  "Carolina Panthers",
  "New Orleans Saints",
  "Tampa Bay Buccaneers",
];
const mvpCandidates = [
  // Top 25 Most Likely Players
  "Patrick Mahomes",
  "Josh Allen",
  "C.J. Stroud",
  "Jalen Hurts",
  "Joe Burrow",
  "Lamar Jackson",
  "Brock Purdy",
  "Dak Prescott",
  "Jared Goff",
  "Tua Tagovailoa",
  "Aaron Rodgers",
  "Justin Herbert",
  "Matthew Stafford",
  "Anthony Richardson",
  "Trevor Lawrence",
  "Kirk Cousins",
  "Kyler Murray",
  "Caleb Williams",
  "Christian McCaffrey",
  "Saquon Barkley",
  "Derrick Henry",
  "Davante Adams",
  "Stefon Diggs",
  "Tyreek Hill",
  "Cooper Kupp",
  "Travis Kelce",
  "Justin Jefferson",
  "Micah Parsons",
  "Josh Jacobs",
  "Justin Fields",
  "Daniel Jones",
  "Jimmy Garoppolo",
  "Deebo Samuel",
  "Nick Chubb",
  "Jamaal Williams",
  "George Kittle",
  "Ja'Marr Chase",
  "DeAndre Hopkins",
  "Dalvin Cook",
  "Jalen Ramsey",
  "Myles Garrett",
  "T.J. Watt",
  "Russell Wilson",
  "Zach Wilson",
  "CeeDee Lamb",
];

const rookieOfTheYearCandidates = [
  "Caleb Williams (Bears)",
  "Jayden Daniels (Commanders)",
  "Marvin Harrison Jr. (Cardinals)",
  "Xavier Worthy (Chiefs)",
  "Malik Nabers (Giants)",
  "Bo Nix (Broncos)",
  "Ladd McConkey (Chargers)",
];

const mostPassingYardsCandidates = [
  "Patrick Mahomes",
  "Tua Tagovailoa",
  "C.J. Stroud",
  "Matthew Stafford",
  "Jared Goff",
  "Dak Prescott",
  "Kirk Cousins",
  "Josh Allen",
  "Baker Mayfield",
  "Trevor Lawrence",
  "Brock Purdy",
  "Joe Burrow",
  "Sam Darnold",
  "Jalen Hurts",
  "Aaron Rodgers",
  "Justin Herbert",
  "Kyler Murray",
  "Will Levis",
  "Caleb Williams",
  "Geno Smith",
  "Derek Carr",
  "Jayden Daniels",
  "Lamar Jackson",
  "Deshaun Watson",
  "Anthony Richardson",
  "Bryce Young",
  "Bo Nix",
  "Daniel Jones",
  "Drake Maye",
  "Gardner Minshew II",
  "Jordan Love",
  "Justin Fields",
  "Russell Wilson",
  "Jacoby Brissett",
  "Michael Penix Jr.",
  "Aidan O'Connell",
  "Joe Flacco",
  "Jameis Winston",
  "Spencer Rattler",
  "J.J. McCarthy",
  "Zach Wilson",
];

const mostReceivingYardsCandidates = [
  "Tyreek Hill",
  "CeeDee Lamb",
  "A.J. Brown",
  "Justin Jefferson",
  "Amon-Ra St. Brown",
  "Ja'Marr Chase",
  "Cooper Kupp",
  "Nico Collins",
  "Garrett Wilson",
  "Rashee Rice",
  "Mike Evans",
  "Jaylen Waddle",
  "DJ Moore",
  "Stefon Diggs",
  "Brandon Aiyuk",
  "Jayden Reed",
  "Davante Adams",
  "Chris Olave",
  "DeVonta Smith",
  "Deebo Samuel Sr.",
  "Drake London",
  "Marvin Harrison Jr.",
  "Calvin Ridley",
  "George Pickens",
  "Terry McLaurin",
  "Amari Cooper",
  "Michael Pittman Jr.",
  "Xavier Worthy",
  "Tank Dell",
  "Zay Flowers",
  "DK Metcalf",
  "Jaxon Smith-Njigba",
];

const mostRushingYardsCandidates = [
  "Jonathan Taylor",
  "Saquon Barkley",
  "Christian McCaffrey",
  "Kyren Williams",
  "Breece Hall",
  "Bijan Robinson",
  "Joe Mixon",
  "Derrick Henry",
  "Josh Jacobs",
  "Rhamondre Stevenson",
  "Jahmyr Gibbs",
  "De'Von Achane",
  "Isiah Pacheco",
  "Kenneth Walker III",
  "Zamir White",
  "Jordan Mason",
  "J.K. Dobbins",
  "David Montgomery",
  "Lamar Jackson",
  "Alvin Kamara",
  "Nick Chubb",
  "James Cook",
  "Jayden Daniels",
  "Travis Etienne Jr.",
  "D'Andre Swift",
  "Jonathan Brooks",
  "Zack Moss",
  "Ezekiel Elliott",
  "Aaron Jones",
  "James Conner",
  "Rachaad White",
  "Najee Harris",
];

// List of all NFL teams for the Superbowl dropdown
const nflTeams = [
  ...afcNorthTeams,
  ...afcEastTeams,
  ...afcWestTeams,
  ...afcSouthTeams,
  ...nfcNorthTeams,
  ...nfcEastTeams,
  ...nfcWestTeams,
  ...nfcSouthTeams,
];

const guessTemplateList = [
  "afc_east_winner",
  "afc_north_winner",
  "afc_south_winner",
  "afc_west_winner",
  "most_receiving_yards",
  "most_running_yards",
  "most_throwing_yards",
  "mvp",
  "nfc_east_winner",
  "nfc_north_winner",
  "nfc_south_winner",
  "nfc_west_winner",
  "rookie_of_the_year",
  "superbowl_winner",
];

//TODO: Mun þurfa að gera eitthvað annað ef notandinn er ekki í neinu league. Einnig, splitta í fleiri componenta, t.d league selectorinn
const SeasonGuesses = ({ leaguesData }) => {
  const [selectedLeague, setSelectedLeague] = useState(leaguesData[0].id);
  const handleSelectLeague = (leagueId) => {
    setSelectedLeague(leagueId);
  };

  const {
    data: seasonGuesses,
    error: seasonGuessesError,
    isLoading: seasonGuessesLoading,
  } = useQuery({
    queryKey: ["seasonGuesses", selectedLeague],
    queryFn: () => fetchSeasonGuessesForLeague(selectedLeague),
    enabled: !!selectedLeague,
  });

  if (seasonGuessesLoading) {
    return <p>Loading...</p>;
  }

  if (seasonGuessesError) {
    return <p>Error fetching season guesses</p>;
  }
  const players = [
    ...new Map(
      seasonGuesses.map((entry) => [entry.player_id, entry.playerName])
    ).entries(),
  ];
  return (
    <Container>
      <p>Season Guesses</p>
      <DropdownButton
        id="dropdown-basic-button"
        title={`League: ${
          selectedLeague
            ? leaguesData.find((league) => league.id === selectedLeague)?.name
            : "Select a League"
        }`}
        onSelect={handleSelectLeague}
        className="mb-3"
      >
        {leaguesData.map((league) => (
          <Dropdown.Item key={league.id} eventKey={league.id}>
            {league.name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Titill?</th>
            {players.map(([playerId, playerName]) => (
              <th key={playerId}>{playerName}</th>
            ))}
            <th>Winner</th>
          </tr>
        </thead>
        <tbody>
          {guessTemplateList.map((guess) => (
            <tr key={guess}>
              <td>{guess}</td>
              {players.map(([playerId]) => (
                <td key={playerId}>
                  {seasonGuesses.find(
                    (entry) => entry.player_id === playerId
                  )?.[guess] || null}
                </td>
              ))}
              <td>
                {seasonGuesses.find((entry) => entry[guess] && entry.is_winner)
                  ?.playerName || null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default SeasonGuesses;
/*
const SeasonGuesses = () => {
  // State to manage user guesses

  const [guesses, setGuesses] = useState({});
  const [loading, setLoading] = useState(true);

  // Handle changes in dropdowns
  const handleChange = (event) => {
    setGuesses({
      ...guesses,
      [event.target.name]: event.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Guesses submitted:", guesses);
    await handleSeasonGuesses(guesses);
    // Add logic to submit the guesses to the backend
  };

  // Function to create dropdowns for division winners
  const renderDivisionWinners = (division, label, teams) => (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm="4">
        {label}
      </Form.Label>
      <Col sm="8">
        <Form.Control
          as="select"
          name={division}
          value={guesses[division]}
          onChange={handleChange}
        >
          <option value="">Select a team</option>
          {teams.map((team) => (
            <option key={team} value={team}>
              {team}
            </option>
          ))}
        </Form.Control>
      </Col>
    </Form.Group>
  );

  //useffect to fetch current guesses

  useEffect(() => {
    const fetchGuesses = async () => {
      const response = await fetchCurrentGuesses();
      if (response.success && response.data) {
        setGuesses(response.data);
      }
      setLoading(false); // Set loading to false after fetching
    };
    fetchGuesses();
  }, []);
  return (
    <Container className="mt-4">
      <h1>
        Tíminn til þess að setja inn season bets er liðinn. Njótið ársins!{" "}
      </h1>
      <h1>Season Guesses</h1>
      {loading && <p>Loading...</p>}
      <Form onSubmit={handleSubmit}>
        // Division Winners 
        <h3>Division Winners, 5 points each</h3>
        {renderDivisionWinners(
          "afc_north_winner",
          "AFC North Winner",
          afcNorthTeams
        )}
        {renderDivisionWinners(
          "afc_east_winner",
          "AFC East Winner",
          afcEastTeams
        )}
        {renderDivisionWinners(
          "afc_west_winner",
          "AFC West Winner",
          afcWestTeams
        )}
        {renderDivisionWinners(
          "afc_south_winner",
          "AFC South Winner",
          afcSouthTeams
        )}
        {renderDivisionWinners(
          "nfc_north_winner",
          "NFC North Winner",
          nfcNorthTeams
        )}
        {renderDivisionWinners(
          "nfc_east_winner",
          "NFC East Winner",
          nfcEastTeams
        )}
        {renderDivisionWinners(
          "nfc_west_winner",
          "NFC West Winner",
          nfcWestTeams
        )}
        {renderDivisionWinners(
          "nfc_south_winner",
          "NFC South Winner",
          nfcSouthTeams
        )}

        // Superbowl Winner 
        <h3>Superbowl Winner, 15 points!</h3>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            Superbowl Winner
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              name="superbowl_winner"
              value={guesses.superbowl_winner} // Set the value to select the user's previous guess
              onChange={handleChange}
            >
              <option value="">Select a team</option>{" "}
              // Static placeholder option
              {nflTeams.map((team) => (
                <option key={team} value={team}>
                  {team}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        // Other Bets
        <h3>Other bets, 8 points each</h3>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            MVP
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              name="mvp"
              value={guesses.mvp}
              onChange={handleChange}
            >
              <option value="">Select a player</option>
              {mvpCandidates.map((player) => (
                <option key={player} value={player}>
                  {player}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            Rookie of the Year
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              name="rookie_of_the_year"
              value={guesses.rookie_of_the_year}
              onChange={handleChange}
            >
              <option value="">Select a player</option>
              {rookieOfTheYearCandidates.map((player) => (
                <option key={player} value={player}>
                  {player}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        // Most Throwing Yards 
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            Most Throwing Yards
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              name="most_throwing_yards"
              value={guesses.most_throwing_yards}
              onChange={handleChange}
            >
              <option value="">Select a player</option>
              {mostPassingYardsCandidates.map((player) => (
                <option key={player} value={player}>
                  {player}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        // Most Receiving Yards 
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            Most Receiving Yards
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              name="most_receiving_yards"
              value={guesses.most_receiving_yards}
              onChange={handleChange}
            >
              <option value="">Select a player</option>
              {mostReceivingYardsCandidates.map((player) => (
                <option key={player} value={player}>
                  {player}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        // Most Rushing Yards
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4">
            Most Rushing Yards
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              name="most_running_yards"
              value={guesses.most_running_yards}
              onChange={handleChange}
            >
              <option value="">Select a player</option>
              {mostRushingYardsCandidates.map((player) => (
                <option key={player} value={player}>
                  {player}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        // Add more dropdowns for other categories
      </Form>
    </Container>
  );
};

export default SeasonGuesses;
*/
