import React from "react";
import { Container } from "react-bootstrap";
import LeaderboardComponent from "../components/LeaderboardComponent";
import { useQuery } from "@tanstack/react-query";
import { fetchUserLeaguesWithNames } from "../services/supabaseService";
import { useParams, useLocation } from "react-router-dom";

// Helper function to parse query parameters
const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const LeaderboardPage = () => {
  // Extract leagueId from URL params or query parameters
  const { leagueId: pathLeagueId } = useParams();
  const {
    data: leaguesDataFetching,
    isLoading: leaguesLoading,
    error: leaguesError,
  } = useQuery({
    queryKey: ["leagues"],
    queryFn: fetchUserLeaguesWithNames,
    staleTime: 1000 * 60 * 5,
  });

  if (leaguesLoading) {
    return <p>Loading...</p>;
  }

  if (leaguesError) {
    return <p>Error fetching leagues</p>;
  }

  if (!leaguesDataFetching || leaguesDataFetching.length === 0) {
    return <p>No leagues found</p>;
  }
  const leagueId = pathLeagueId ? pathLeagueId : leaguesDataFetching[0].id;
  return (
    <Container>
      <LeaderboardComponent
        leaguesData={leaguesDataFetching}
        leagueId={leagueId}
      />
    </Container>
  );
};

export default LeaderboardPage;
