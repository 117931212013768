// src/components/UpdatePassword.js

import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";

const UpdatePassword = () => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState("success");
  const navigate = useNavigate();

  const handleVerifyToken = async (e) => {
    e.preventDefault();

    // Verify the token
    const { error } = await supabase.auth.verifyOtp({
      email,
      token,
      type: "recovery",
    });

    if (error) {
      setMessage(`Error verifying token: ${error.message}`);
      setMessageVariant("danger");
    } else {
      // Token is valid; update the password
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (updateError) {
        setMessage(`Error updating password: ${updateError.message}`);
        setMessageVariant("danger");
      } else {
        setMessage("Your password has been updated!");
        setMessageVariant("success");
        // Redirect the user after a delay
        setTimeout(() => {
          navigate("/auth"); // Redirect to login page
        }, 2000);
      }
    }
  };

  return (
    <Container style={{ maxWidth: "500px", marginTop: "50px" }}>
      <h2>Reset Your Password</h2>
      {message && <Alert variant={messageVariant}>{message}</Alert>}
      <Form onSubmit={handleVerifyToken}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formToken" style={{ marginTop: "20px" }}>
          <Form.Label>Reset Token</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the reset token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formNewPassword" style={{ marginTop: "20px" }}>
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" style={{ marginTop: "20px" }}>
          Reset Password
        </Button>
      </Form>
    </Container>
  );
};

export default UpdatePassword;
