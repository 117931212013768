import React from "react";
import { Card } from "react-bootstrap";
import TeamIcon from "./TeamIcon"; // Import the TeamIcon component

const GameCard = ({ game, player, guessedTeam, onGuess }) => {
  const hasStarted = new Date(game.kickofftime) <= new Date();
  //const hasStarted = false; // Hardcode to false for testing
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const kickofftime = new Date(game.kickofftime).toLocaleString(
    "en-US",
    options
  );
  return (
    <Card
      className={`mb-3 shadow-sm rounded-lg border-0 ${
        hasStarted ? "bg-light text-muted" : "bg-white"
      }`}
    >
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          {/* Team 2 */}
          <div
            className={`d-flex align-items-center flex-grow-1 justify-content-center p-3 ${
              !hasStarted ? "guess-team" : ""
            } ${guessedTeam === "2" ? "bg-success text-white rounded" : ""}`}
            onClick={() => !hasStarted && onGuess(game.id, "2")}
            style={{
              cursor: !hasStarted ? "pointer" : "default",
              transition: "background-color 0.3s",
            }}
          >
            <TeamIcon teamName={game.team2} />
            <span className="ms-2 fw-bold">{game.team2}</span>
          </div>

          {/* Versus */}
          <span className="mx-4 text-secondary fw-bold"> @ </span>
          {/* Team 1 */}
          <div
            className={`d-flex align-items-center flex-grow-1 justify-content-center p-3 ${
              !hasStarted ? "guess-team" : ""
            } ${guessedTeam === "1" ? "bg-success text-white rounded" : ""}`}
            onClick={() => !hasStarted && onGuess(game.id, "1")}
            style={{
              cursor: !hasStarted ? "pointer" : "default",
              transition: "background-color 0.3s",
            }}
          >
            <span className="me-2 fw-bold">{game.team1}</span>
            <TeamIcon teamName={game.team1} />
          </div>
        </div>
        <div className="text-center mt-2">
          <span className=" text-secondary fw-bold  ">
            Start time: {kickofftime}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
};

export default GameCard;
