// src/components/ForgotPassword.js

import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Alert } from "react-bootstrap";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState("success");
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      setMessage(error.message);
      setMessageVariant("danger");
    } else {
      setMessage("A reset token has been sent to your email.");
      setMessageVariant("success");
      // Optionally redirect to the token verification page
      navigate("/update-password");
    }
  };

  return (
    <Container style={{ maxWidth: "500px", marginTop: "50px" }}>
      <h2>Forgot Password</h2>
      {message && <Alert variant={messageVariant}>{message}</Alert>}
      <Form onSubmit={handleForgotPassword}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" style={{ marginTop: "20px" }}>
          Send Reset Token
        </Button>
      </Form>
    </Container>
  );
};

export default ForgotPassword;
