import React from "react";
import { Container } from "react-bootstrap";
import CreateLeague from "../components/CreateLeague";
import JoinLeague from "../components/JoinLeague";

const LeagueManagement = () => {
  return (
    <Container className="mt-4">
      <h1>League Management</h1>
      <CreateLeague />
      <hr />
      <JoinLeague />
    </Container>
  );
};

export default LeagueManagement;
