import React from "react";

const teamAbbreviations = {
  "Arizona Cardinals": "ARI",
  "Atlanta Falcons": "ATL",
  "Baltimore Ravens": "BAL",
  "Buffalo Bills": "BUF",
  "Carolina Panthers": "CAR",
  "Chicago Bears": "CHI",
  "Cincinnati Bengals": "CIN",
  "Cleveland Browns": "CLE",
  "Dallas Cowboys": "DAL",
  "Denver Broncos": "DEN",
  "Detroit Lions": "DET",
  "Green Bay Packers": "GB",
  "Houston Texans": "HOU",
  "Indianapolis Colts": "IND",
  "Jacksonville Jaguars": "JAX",
  "Kansas City Chiefs": "KC",
  "Las Vegas Raiders": "LV",
  "Los Angeles Chargers": "LAC",
  "Los Angeles Rams": "LAR",
  "Miami Dolphins": "MIA",
  "Minnesota Vikings": "MIN",
  "New England Patriots": "NE",
  "New Orleans Saints": "NO",
  "New York Giants": "NYG",
  "New York Jets": "NYJ",
  "Philadelphia Eagles": "PHI",
  "Pittsburgh Steelers": "PIT",
  "San Francisco 49ers": "SF",
  "Seattle Seahawks": "SEA",
  "Tampa Bay Buccaneers": "TB",
  "Tennessee Titans": "TEN",
  "Washington Commanders": "WAS",
  Arsenal: "Arsenal",
  "Aston Villa": "Aston-Villa",
  Brentford: "Brentford",
  Bournemouth: "AFC-Bournemouth",
  Brighton: "Brighton",
  Burnley: "Burnley",
  Chelsea: "Chelsea",
  "Crystal Palace": "Crystal-Palace",
  Everton: "Everton",
  Fulham: "Fulham",
  Ipswich: "Ipswich-Town",
  Leicester: "Leicester-City",
  Liverpool: "Liverpool",
  "Man City": "Manchester-City",
  "Man Utd": "Manchester-United",
  Newcastle: "Newcastle-United",
  "Nott'm Forest": "Nottingham-Forest",
  Southampton: "Southampton",
  Spurs: "Tottenham",
  "West Ham": "West-Ham-United",
  Wolves: "Wolverhampton-Wanderers",
};

const TeamIcon = ({ teamName }) => {
  const teamAbbr = teamAbbreviations[teamName]; // Get the abbreviation using the full team name

  if (!teamAbbr) {
    // Handle cases where the team name is not found in the mapping
    return <span>Unknown Team</span>;
  }

  // Construct the image path dynamically
  const imgPath = `/icons/team_logos/${teamAbbr}.png`; // Assuming images are in 'public/icons' folder
  return (
    <img
      src={imgPath}
      alt={`${teamName} icon`}
      style={{ width: "30px", height: "30px" }}
    />
  );
};

export default TeamIcon;
