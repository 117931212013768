import React from "react";
import { Card } from "react-bootstrap";
import TeamIcon from "./TeamIcon"; // Import the TeamIcon component

const GameCard = ({ game, user, guessedTeam, winner }) => {
  const hasStarted = new Date(game.kickofftime) <= new Date();
  //const hasStarted = false; // Hardcode to false for testing

  return (
    <Card className={`mb-3 shadow-sm rounded-lg border-0 bg-light `}>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          {/* Team 2 */}
          <div
            className={`d-flex align-items-center flex-grow-1 justify-content-center p-3 ${
              !hasStarted ? "guess-team" : ""
            } ${
              guessedTeam === "2"
                ? winner === "2"
                  ? "bg-success text-white rounded"
                  : "bg-danger text-white rounded"
                : ""
            }`}
            style={{
              cursor: !hasStarted ? "pointer" : "default",
              transition: "background-color 0.3s",
            }}
          >
            <TeamIcon teamName={game.team2} />
            <span className="ms-2 fw-bold">{game.team2}</span>
          </div>

          <span className="mx-4 text-secondary fw-bold">
            {" "}
            {game.team2_score}{" "}
          </span>

          {/* Versus */}
          <span className="mx-4 text-secondary fw-bold"> @ </span>

          <span className="mx-4 text-secondary fw-bold">
            {" "}
            {game.team1_score}{" "}
          </span>
          {/* Team 1 */}
          <div
            className={`d-flex align-items-center flex-grow-1 justify-content-center p-3 ${
              !hasStarted ? "guess-team" : ""
            } ${
              guessedTeam === "1"
                ? winner === "1"
                  ? "bg-success text-white rounded"
                  : "bg-danger text-white rounded"
                : ""
            }`}
            style={{
              cursor: !hasStarted ? "pointer" : "default",
              transition: "background-color 0.3s",
            }}
          >
            <span className="me-2 fw-bold">{game.team1}</span>
            <TeamIcon teamName={game.team1} />
          </div>
        </div>

        {/* Game Status */}
        {hasStarted && (
          <div className="text-center mt-2">
            <p className="fw-bold text-danger">⌚GAME HAS STARTED⌚</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default GameCard;
