import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchUserLeaguesAndRank } from "../services/supabaseService";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Nav } from "react-bootstrap";

const UserLeagues = () => {
  const {
    data: leagueAndRankData,
    isLoading: leagueAndRankLoading,
    error: leagueAndRankError,
  } = useQuery({
    queryKey: ["leaguesAndRank"],
    queryFn: fetchUserLeaguesAndRank,
    staleTime: 1000 * 60 * 5,
  });
  if (leagueAndRankLoading) {
    return <p>Loading...</p>;
  }
  if (leagueAndRankError) {
    return <p>Error fetching leagues and rank</p>;
  }
  if (!leagueAndRankData || leagueAndRankData.length === 0) {
    return <p>No leagues found</p>;
  }

  return (
    <div>
      <h1>Your leagues</h1>
      <Table hover striped border sm>
        <thead>
          <tr>
            <th scope="col">League Name</th>
            <th scope="col">Rank</th>
          </tr>
        </thead>
        <tbody>
          {leagueAndRankData.map((league) => (
            <tr class="hover-overlay">
              <LinkContainer
                to={`/leaderboard/${league.leagueId}`}
                key={league.leagueId}
              >
                <Nav.Link>
                  <td>{league.leagueName}</td>
                </Nav.Link>
              </LinkContainer>
              <td>{league.rank}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UserLeagues;
