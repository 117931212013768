import React, { useState } from "react";
import { Form, Button, Alert, Container } from "react-bootstrap";
import { joinLeague } from "../services/supabaseService"; // Import service function

const JoinLeague = () => {
  const [inviteCode, setInviteCode] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleJoinLeague = async (e) => {
    e.preventDefault();
    try {
      await joinLeague(inviteCode);
      setSuccess("Successfully joined the league!");
      setError(null);
    } catch (err) {
      setError("Failed to join league: " + err.message);
      setSuccess(null);
    }
  };

  return (
    <Container>
      <h3>Join an Existing League</h3>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={handleJoinLeague}>
        <Form.Group controlId="inviteCode">
          <Form.Label>Invite Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter invite code"
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
            required
          />
        </Form.Group>
        <button type="button" class="btn btn-success">
          Join League
        </button>
      </Form>
    </Container>
  );
};

export default JoinLeague;
