import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaTrophy, FaFootballBall, FaUsers } from "react-icons/fa"; // Import icons
import { API_BASE_URL } from "../apiconfig";
import UserLeagues from "../components/UserLeagues";
import GameGuesses from "./GameGuesses";

const updateGame = async (gameId, winner) => {
  try {
    const response = await fetch(`${API_BASE_URL}/updatePlayerName`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ gameId, winner }),
    });

    const result = await response.json();

    if (!response.ok) {
      console.error("Error updating player name:", result.error);
      return { success: false, error: result.error };
    }

    return { success: true, data: result.data };
  } catch (error) {
    console.error("Unexpected error updating player name:", error);
    return { success: false, error };
  }
};
const HomeScreen = () => {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <GameGuesses />
        </Col>
        <Col xs={3}>
          <UserLeagues />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeScreen;
