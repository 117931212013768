import React from "react";
import { Container, Nav, Navbar as BootstrapNavbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap"; // Helper to use React Router Links with React-Bootstrap
import { handleLogOut } from "../services/supabaseService";
import { useQueryClient } from "@tanstack/react-query";

const Navbar = () => {
  const queryClient = useQueryClient();
  const logOut = () => {
    queryClient.clear();
    handleLogOut();
  };
  return (
    <BootstrapNavbar bg="dark" variant="dark" expand="lg">
      <Container>
        <LinkContainer to="/">
          <BootstrapNavbar.Brand>NFL Guessing Game</BootstrapNavbar.Brand>
        </LinkContainer>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/leaderboard">
              <Nav.Link>Leaderboard</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/games">
              <Nav.Link>Games</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/league">
              <Nav.Link>League Management</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/SeasonGuesses">
              <Nav.Link>Season guesses</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/UserManagement">
              <Nav.Link>User Management</Nav.Link>
            </LinkContainer>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
      <button type="button" class="btn btn-danger" onClick={logOut}>
        Útskrá
      </button>
    </BootstrapNavbar>
  );
};

export default Navbar;
